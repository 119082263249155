<template>
  <div class="sqmsg">
    <div class="tipBox">
      <p>创建签名：</p>
      <div class="tip1">
        短信签名：
        <el-input v-model="inputData.sign_name" placeholder style="width:15%"></el-input>
      </div>
      <div class="tip1">
        证明文件：
        <el-upload
          class="tip1upload"
          v-model="inputData.empower_img"
          :action="`${MixinUploadApi}?scene=shop`"
          list-type="picture-card"
          :on-remove="handleRemove"
          :on-success="uploadok"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt/>
        </el-dialog>
        <!--        <el-upload
					class="upload-demo"
					key="shop_logo"
					:action="`${MixinUploadApi}?scene=shop`"
					:on-success="uploadSuccessLogo"
					:before-upload="handleImagesUrlBefore"
					:file-list="fileList_logo"
					ref="fileList_logo"
					list-type="picture">
					<el-button size="small" type="primary">
						上传头像<i class="el-icon-upload el-icon--right"></i>
					</el-button>

        </el-upload>-->
        <div style="margin-left:70px">
          <el-upload
            class="upload-demo"
            :action="`${MixinUploadApi}?scene=shop`"
            v-model="inputData.licence_img"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :on-exceed="handleExceed"
            :file-list="fileList"
            :on-success="uploadok1"
          >
            <el-button size="small" type="primary">申请企业的营业执照</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </div>
      </div>
      <div class="tip1">
        <p>1、请上传申请企业的营业执照;</p>
        <p>2、支持JPG、PNG、GIF、JPEG格式的图片，每张图片不能大于1MB</p>
      </div>
    </div>
    <div class="btnn">
      <el-button class="submitbtn" @click="czShow" type="primary">提交申请</el-button>
    </div>
  </div>
</template>
<script>
import * as API_account from '@/api/account';

export default {
  data() {
    return {
      input: '',
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      inputData: {
        sign_name: '',
        licence_img: '',
        empower_img: ''
      }
    };
  },
  methods: {
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    // handlePreview(file) {
    //     console.log(file,"file");
    //   },
    handleRemove(file, fileList) {
      console.log(file, fileList, 'file,filelist');
    },

    uploadok(response, file, fileList) {
      this.inputData.empower_img = response.url
      console.log(response, 'file')
    },
    uploadok1(response, file, fileList) {
      this.inputData.licence_img = response.url
      console.log(response, 'file')
    },
    czShow() {
      console.log(this.inputData, 'ressssss');
      let params = this.inputData
      API_account.addManage(params).then(res => {
        console.log(res, 'resssaaaaaaa');
        this.$parent.flagFalse();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.btnn {
  background-color: #f5f6f9;
  padding-top: 50px;
  text-align: center;
}

.tip1 {
  margin-left: 200px;
  margin-bottom: 20px;
}

.tip1upload {
  display: inline-block;
}

.tip1 .el-upload--picture-card {
  border: 1px solid #c0ccda;
}

.tipBox {
  background: #fff;
  padding: 20px;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.tipBox p {
  font-size: 18px;
  color: #666666;
}

.tip1 {
  color: #666666;
}
</style>
